import React from "react";
import CardButton from "./CardButton";
import "./Card.css";

interface Props {
  title: string;
  description: string;
  image: string;
  url: string;
}

function Card(props: Props) {
  return (
    <div className="Card">
      <h2 className="Card-title">{props.title}</h2>
      <p className="Card-description">{props.description}</p>
      <img src={props.image} className="Card-image" alt="logo" />
      
      <div className="Card-buttons">
        <CardButton url={props.url}/>
      </div>
    </div>
  );
}

export default Card;
