import React from "react";
import logo from "../../assets/mc_hrz_pos.svg";
import "./Header.css";

function Header() {
  return (
    <header className="App-header">
      <h1 className="App-header-title">Welcome to AnyBank</h1>
      <img src={logo} className="App-header-logo" alt="logo" />
    </header>
  );
}

export default Header;
