import React from "react";
import "./CardButton.css";
import iosBtn from "../../assets/add-to-wallet-ios.png";
import yourpassLogo from "../../assets/icon.svg";
import { isIOS, isAndroid } from "react-device-detect";

interface Props {
  url: string;
}

function addPkpassParam(input: string) {
  var url = new URL(input);
  url.searchParams.append("format", "pkpass");
  return url.toString();
}

// ios
function iosUrl(pkpassUrl: string) {
  const pk = addPkpassParam(pkpassUrl);
  return pk;
}

// function iosOpenWallet() {
//   window.open("shoebox://");
// }

// android
function androidUrl(pkpassUrl: string) {
  const pk = addPkpassParam(pkpassUrl);
  const url =
    pk.replace(/^(https?:)/, "intent:") +
    "#Intent;scheme=yourwallet;package=eu.yourpass.wallet;S.market_referrer=" +
    pk +
    ";end";
  return url;
}

function Card(props: Props) {
  if (isIOS)
    return (
      <a href={iosUrl(props.url)}>
        <img src={iosBtn} alt="add to wallet" className="CardButton-ios" />
      </a>
    );

  if (isAndroid) {
    return (
      <div className="CardButton-android">
        <a href={androidUrl(props.url)}>
          <span>
            <img
              src={yourpassLogo}
              alt="add to wallet"
              className="CardButton-android-img"
            />
            <small>Add to</small>
            <br />
            Yourwallet
          </span>
        </a>
      </div>
    );
  }

  return (
    <a href={props.url} className="CardButton-desktop" target="_blank" rel="noopener noreferrer">
      Add to wallet
    </a>
  );
}

export default Card;
