import React from "react";
import "./Body.css";
import Card from "./Card";
import cardLounge from "../../assets/card-lounge.png";
import cardInsruence from "../../assets/card-insruance.png";
import cardInfo from "../../assets/card-info.png";

const cards = [
  {
    url:
      "https://get.yourpass.eu/a19e60cf-966d-4b15-a079-e7436edf3658?tag=MC_web",
    title: "Lounge card",
    description:
      "Your Lounge pass helps you access the best global airport lounges. Add this pass for uses full information and finding your nearest lounge.",
    image: cardLounge,
  },
  {
    url:
      "https://get.yourpass.eu/ecbb5c1a-e469-40d2-867f-57ce27e92f1d?tag=MC_web",
    title: "Insurance card",
    description:
      "All your AnyBank insurance policy in one place on your mobile wallet. Review your policy details of contact us in an emergency for 24/7 assistance.",
    image: cardInsruence,
  },
  {
    url:
      "https://get.yourpass.eu/476e412a-6ae7-484e-b9ef-83fdb9cf9f03?tag=MC_web",
    title: "Info card",
    description:
      "Add this pass to your wallet to have useful banking information to hand, from emergency contact details to terms and conditions.   ",
    image: cardInfo,
  },
];

function Body() {
  return (
    <div className="App-body">
      <p>
        To help you access your card products we have made a range of passes
        which you can add to your mobile wallet. Check your passes for helpful
        information regarding our products and services.
      </p>
      <div className="App-body-cards">
        {cards.map((c) => (
          <Card
            key={c.url}
            title={c.title}
            url={c.url}
            description={c.description}
            image={c.image}
          />
        ))}
      </div>
    </div>
  );
}

export default Body;
