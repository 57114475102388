import React, { useState } from "react";
import "./Warning.css";
import logo from "../../assets/mc_hrz_pos.svg";

function Warning() {
  const [show, setShow] = useState(localStorage.getItem("show"));
  if (show === null) {
    return (
      <div className="Warning">
        <div className="Warning-container">
          <img className="Warning-logo" src={logo} alt="mastercard" />
          <h1 className="Warning-title">Welcome to AnyBank</h1>
          <p className="Warning-description">
            This application was created for presentation purposes only and is not intended for production use.
          </p>
          <div className="Warning-control-container">
            <button
              className="Warning-control-button"
              onClick={() => {
                const date = new Date().toISOString();
                setShow(date);
                localStorage.setItem("show", date);
              }}
            >
              Click to acknowledge
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default Warning;
